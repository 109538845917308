import React from "react";
import { UserAuth } from "./context/AuthContext";
import { database } from "./firebase";
import { setDoc, collection, doc, getDoc, getDocs, updateDoc, addDoc, where, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from "uuid";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const AccountStudent = () => {
    const { user } = UserAuth();
    const [userName, setUserName] = useState("User");
    const [newLocalName, setNewLocalName] = useState("");
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();
    const [content, setContent] = useState("");
    const [numberOfTests, setNumberOfTests] = useState([0]);
    const [data, setData] = useState([]);
    const pathToTheBase = collection(database, "exercises");
    const [toggle, setToggle] = useState(false);

    function toggleReload() {
        if(toggle === false) {
            setToggle(true)
        } else {
            setToggle(false)
        }
    };
    const [currentUserSubsInfo, setCurrentUserSubsInfo] = useState("");

    const passTest = <PassTest data={data} numberOfTests={numberOfTests} currentUserSubsInfo={currentUserSubsInfo} />;
    const passedTests = <PassedTests currentUserId={user.auth.currentUser.uid} />;

    
    const showTestsToPass = (e) => {
        setContent(passTest);
        setActiveButton(e);
        toggleReload();
    };

    const showPassedTests = (e) => {
        setContent(passedTests);
        setActiveButton(e);
        toggleReload();
    };

    async function addUserData() {
        await setDoc(doc(database, "users", user.auth.currentUser.uid), {
            name: user.auth.currentUser.displayName,
            role: "student",
            id: user.auth.currentUser.uid
          });
    };

    function getUserData() {
        const getData = async() => {
            const docRef = doc(database, "users", user.auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                setUserName(docSnap.data().localName);
                if(docSnap.data().role === "admin") {
                    navigate('/accountadmin')
                };
                if(docSnap.data().role === "special") {
                    navigate('/solarspanish')
                };
                setUserName(docSnap.data().name)
            //   console.log("Document data:", docSnap.data());
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document!");
            //   addUserData();
            }
        }
        getData();
    };

    useEffect(()=> {
        var array = [];
        
        const getData = async() => {
            setIsLoading(true)
            const dbData = await getDocs(pathToTheBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            receivedData.map(values => (
                values.questionNumber < 29 ? array.push(values.testNumber+"1") : array.push(values.testNumber+"2")
            ));
            const uniqueArray = [...new Set(array)];
            setData(receivedData);
            setNumberOfTests(uniqueArray.reverse());

            const userSubscriptionRef = collection(database, "userSubscriptions");
            const q = query(userSubscriptionRef, where("userId", "==", user.auth.currentUser.uid));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setCurrentUserSubsInfo(doc.data());
            });
            setIsLoading(false)
        }
        getData()
        getUserData();
        // console.log("data received")
    },[toggle]);

    async function changeName() {
        setIsLoading(true);
        await updateDoc(doc(database, "users", user.auth.currentUser.uid), {
            localName: newLocalName
        });
        setUserName(newLocalName);
        setIsLoading(false);
        const input = document.getElementById('inputChangeName');
        input.classList.add("hidden");
        setNewLocalName("");
    };

    const showInputToChangeName = () => {
        const input = document.getElementById('inputChangeName');
        input.classList.remove("hidden");
    };

    const setActiveButton = (e) => {
        var buttons = document.querySelectorAll('[data-mainMenuButton]');
        for(let i = 0; i<buttons.length; i++) {
            buttons[i].classList.remove("bg-orange-400");
            buttons[i].classList.add("bg-cyan-500");
        };
        e.target.classList.remove("bg-cyan-500");
        e.target.classList.add("bg-orange-400");
    };

    // Modal - JavaScript
    const [userNameModal, setUserNameModal] = useState('');
    const [userSurnameModal, setUserSurnameModal] = useState('');
    const modal = document.getElementById("modal");

    function showModalWindow() {
        modal.showModal();
    }

    function closeModalWindow() {
        modal.close();
    }

    const sendNameSurname = async (e) => {
        e.preventDefault()
        await updateDoc(doc(database, "users", user.auth.currentUser.uid), {
            name: userNameModal + " " + userSurnameModal,
            localName: userNameModal + " " + userSurnameModal
        });
        setUserName(userNameModal + " " + userSurnameModal);
        closeModalWindow();
        setUserNameModal("");
        setUserSurnameModal("");
    }

    if(userName === "Имя отсутствует") {
        showModalWindow();
    }

    return (
        <div>
            <section className="bg-gray-100 bg-center bg-fixed py-3">
                <div className="container mx-auto py-3 text-center flex flex-wrap justify-center">
                    <h1 className="text-black text-2xl sm:text-4xl md:text-4xl lg:text-4xl font-bold">Личный кабинет студента {userName}</h1>
                    <sup><img className="h-6 w-6 ml-4" src="pencil.png" /></sup><p onClick={showModalWindow} className="cursor-pointer">(изменить имя)</p>
                    {/* <div className="flex flex-wrap hidden" id="inputChangeName">
                        <input value={newLocalName} onChange={(e) => setNewLocalName(e.target.value)} className="ml-2 appearance-none block w-6/6 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Введите новое имя"></input>
                        <button onClick={showModalWindow} id="openModal" className="ml-1 bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Готово"}</button>
                    </div> */}
                </div>
            </section>
            <section className="bg-white py-4 px-2 space-x-2">
                <button onClick={showTestsToPass} data-mainMenuButton="0" className="bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    {isLoading ? "Загрузка..." : "Пройти тесты"}
                </button>
                <button onClick={showPassedTests} data-mainMenuButton="1" className="bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    {isLoading ? "Загрузка..." : "Мои результаты"}
                </button>
            </section>
            {content}
            <dialog id="modal" className="p-10 bg-blue-100 rounded-2xl border border-gray-400">
                <div className="flex flex-col">
                    <form onSubmit={sendNameSurname}>
                        <h1>Введите Ваши данные</h1>
                        <div className="flex flex-col py-2 px-4">
                            <label className="py-0 font-small text-right text-sm">Имя</label>
                            <input onChange={(e) => setUserNameModal(e.target.value)} className="border p-3 rounded-lg" type="text" required />
                        </div>
                        <div className="flex flex-col px-4">
                            <label className="py-0 font-small text-right text-sm">Фамилия</label>
                            <input onChange={(e) => setUserSurnameModal(e.target.value)} className="border p-3 rounded-lg" type="text" required />
                        </div>
                        <div className="flex flex-row justify-end">
                            <button id="closeModal" className="m-1 p-3 mr-4 bg-amber-500 hover:bg-amber-400 rounded-lg">Готово</button>
                        </div>
                    </form>
                </div>
            </dialog>
        </div>
    )
}

export default AccountStudent;

function PassTest({data, numberOfTests, currentUserSubsInfo}) {
    const { user } = UserAuth();

    function containsNumber(str) {
        var newArrayForString = [];
        if(str !== undefined){
            for (let i = 0; i < str.length; i++) {
                if(/\d/.test(str[i]) === true) {
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else if(str[i] === '0') {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    } else {
                        newArrayForString.push("<sub>"+str[i]+"</sub>");
                    }
                } else if(str[i] === '@') {
                    // newArrayForString.push("");
                } else if(str[i] === '+'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    };
                } else if(str[i] === '-'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    };
                } else {
                    newArrayForString.push(str[i]);
                };
            };
            return newArrayForString.join('');
        } else {
            return str;
        };
        // Check if the string contains any digit between 0 and 9
    };

    const [showButton, setShowButton] = useState(false);
    const [currentTestNumber, setCurrentTestNumber] = useState("");

    const [currentTestFirstEx, setCurrentTestFirstEx] = useState([]);
    const [currentTestSecondEx, setCurrentTestSecondEx] = useState([]);
    const [currentTestThirdEx, setCurrentTestThirdEx] = useState([]);
    const [currentTestFourthEx, setCurrentTestFourthEx] = useState([]);
    const [currentTestFifthEx, setCurrentTestFifthEx] = useState([]);
    const [currentTestSixthEx, setCurrentTestSixthEx] = useState([]);
    const [currentTestSeventhEx, setCurrentTestSeventhEx] = useState([]);
    const [currentTestEightEx, setCurrentTestEightEx] = useState([]);
    const [currentTestNinethEx, setCurrentTestNinethEx] = useState([]);
    const [currentTestTenthEx, setCurrentTestTenthEx] = useState([]);
    const [currentTestElevenhtEx, setCurrentTestElevenEx] = useState([]);
    const [currentTestTwelveEx, setCurrentTestTwelveEx] = useState([]);
    const [currentTestThirteenEx, setCurrentTestThirteenEx] = useState([]);
    const [currentTestFourteenEx, setCurrentTestFourteenEx] = useState([]);
    const [currentTestFifteenEx, setCurrentTestFifteenEx] = useState([]);
    const [currentTestSixteenEx, setCurrentTestSixteenEx] = useState([]);
    const [currentTestSeventeenEx, setCurrentTestSeventeenEx] = useState([]);
    const [currentTestEighteenEx, setCurrentTestEighteenEx] = useState([]);
    const [currentTestNineteenEx, setCurrentTestNineteenEx] = useState([]);
    const [currentTestTwentyEx, setCurrentTestTwentyEx] = useState([]);
    const [currentTestTwentyOneEx, setCurrentTestTwentyOneEx] = useState([]);
    const [currentTestTwentyTwoEx, setCurrentTestTwentyTwoEx] = useState([]);
    const [currentTestTwentyThreeEx, setCurrentTestTwentyThreeEx] = useState([]);
    const [currentTestTwentyFourEx, setCurrentTestTwentyFourEx] = useState([]);
    const [currentTestTwentyFiveEx, setCurrentTestTwentyFiveEx] = useState([]);
    const [currentTestTwentySixEx, setCurrentTestTwentySixEx] = useState([]);
    const [currentTestTwentySevenEx, setCurrentTestTwentySevenEx] = useState([]);
    const [currentTestTwentyEightEx, setCurrentTestTwentyEightEx] = useState([]);
    const [currentTestTwentyNineEx, setCurrentTestTwentyNineEx] = useState([]);
    const [currentTestThirtyEx, setCurrentTestThirtyEx] = useState([]);
    const [currentTestThirtyOneEx, setCurrentTestThirtyOneEx] = useState([]);
    const [currentTestThirtyTwoEx, setCurrentTestThirtyTwoEx] = useState([]);
    const [currentTestThirtyThreeEx, setCurrentTestThirtyThreeEx] = useState([]);
    const [currentTestThirtyFourEx, setCurrentTestThirtyFourEx] = useState([]);

    var isRight1 = true;
    var isRight2 = true;
    var isRight3 = true;
    var isRight4 = true;
    var isRight5 = true;
    var isRight6 = true;
    var isRight7 = true;
    var isRight8 = true;
    var isRight9 = true;
    var isRight10 = true;
    var isRight11 = true;
    var isRight12 = true;
    var isRight13 = true;
    var isRight14 = true;
    var isRight15 = true;
    var isRight16 = true;
    var isRight17 = true;
    var isRight18 = true;
    var isRight19 = true;
    var isRight20 = true;
    var isRight21 = true;
    var isRight22 = true;
    var isRight23 = true;
    var isRight24 = true;
    var isRight25 = true;
    var isRight26 = true;
    var isRight27 = true;
    var isRight28 = true;

    const [isLoading, setIsLoading] = useState();
    const [isLoadingSecond, setIsLoadingSecond] = useState();

    const pathToUserData = collection(database, "userAnswers");

    const hadleCompleteExerciseFirstPart = async (e) => {
        let message = "Необходимо ответить на ВСЕ вопросы"
        let date = new Date();
        e.preventDefault();
        if(isLoading === ""){
            alert(message)
        } else if(isLoading === ""){
            alert(message)
        } else if(currentTestTwelveExAnswer.length === 0){
            alert("Выполните задание 12")
        } else if(currentTestSeventeenExAnswer.length === 0){
            alert("Выполните задание 17")
        } else if(currentTestEighteenExAnswer.length === 0){
            alert("Выполните задание 18")
        } else if(currentTestTwentySixExAnswer.length === 0){
            alert("Выполните задание 26")
        } else if(currentTestTwentySevenExAnswer.length === 0){
            alert("Выполните задание 27")
        } else if(currentTestTwentyEightExAnswer.length === 0){
            alert("Выполните задание 28")
        } else {
            setIsLoading(true);
            const totalScore = checkAllAnswers();
            const { id: documentId } = await addDoc(pathToUserData, {
                userId: user.auth.currentUser.uid,
                date: date,
                testNumber: currentTestNumber,
                score: totalScore,
                question1: [currentTestFirstExAnswer1, currentTestFirstExAnswer2],
                question2: [currentTestSecondExAnswer1, currentTestSecondExAnswer2, currentTestSecondExAnswer3],
                question3: [currentTestThirdExAnswer1, currentTestThirdExAnswer2],
                question4: [currentTestFourthExAnswer1, currentTestFourthExAnswer2],
                question5: [currentTestFifthExAnswer1, currentTestFifthExAnswer2, currentTestFifthExAnswer3],
                question6: [currentTestSixthExAnswer1, currentTestSixthExAnswer2],
                question7: [currentTestSeventhExAnswer1, currentTestSeventhExAnswer2, currentTestSeventhExAnswer3, currentTestSeventhExAnswer4],
                question8: [currentTestEightExAnswer1, currentTestEightExAnswer2, currentTestEightExAnswer3, currentTestEightExAnswer4],
                question9: [currentTestNinethExAnswer1, currentTestNinethExAnswer2],
                question10: [currentTestTenthExAnswer1, currentTestTenthExAnswer2, currentTestTenthExAnswer3],
                question11: [currentTestEleventhExAnswer1, currentTestEleventhExAnswer2],
                question12: currentTestTwelveExAnswer,
                question13: [currentTestThirteenExAnswer1, currentTestThirteenExAnswer2],
                question14: [currentTestFourteenExAnswer1, currentTestFourteenExAnswer2, currentTestFourteenExAnswer3, currentTestFourteenExAnswer4],
                question15: [currentTestFifteenExAnswer1, currentTestFifteenExAnswer2, currentTestFifteenExAnswer3, currentTestFifteenExAnswer4],
                question16: [currentTestSixteenExAnswer1, currentTestSixteenExAnswer2],
                question17: currentTestSeventeenExAnswer,
                question18: currentTestEighteenExAnswer,
                question19: [currentTestNineteenExAnswer1, currentTestNineteenExAnswer2, currentTestNineteenExAnswer3],
                question20: [currentTestTwentyExAnswer1, currentTestTwentyExAnswer2, currentTestTwentyExAnswer3],
                question21: [currentTestTwentyOneExAnswer1, currentTestTwentyOneExAnswer2, currentTestTwentyOneExAnswer3, currentTestTwentyOneExAnswer4],
                question22: [currentTestTwentyTwoExAnswer1, currentTestTwentyTwoExAnswer2, currentTestTwentyTwoExAnswer3, currentTestTwentyTwoExAnswer4],
                question23: [currentTestTwentyThreeExAnswer1, currentTestTwentyThreeExAnswer2],
                question24: [currentTestTwentyFourExAnswer1, currentTestTwentyFourExAnswer2, currentTestTwentyFourExAnswer3, currentTestTwentyFourExAnswer4],
                question25: [currentTestTwentyFiveExAnswer1, currentTestTwentyFiveExAnswer2, currentTestTwentyFiveExAnswer3],
                question26: currentTestTwentySixExAnswer,
                question27: currentTestTwentySevenExAnswer,
                question28: currentTestTwentyEightExAnswer,
                isRight1: isRight1,
                isRight2: isRight2,
                isRight3: isRight3,
                isRight4: isRight4,
                isRight5: isRight5,
                isRight6: isRight6,
                isRight7: isRight7,
                isRight8: isRight8,
                isRight9: isRight9,
                isRight10: isRight10,
                isRight11: isRight11,
                isRight12: isRight12,
                isRight13: isRight13,
                isRight14: isRight14,
                isRight15: isRight15,
                isRight16: isRight16,
                isRight17: isRight17,
                isRight18: isRight18,
                isRight19: isRight19,
                isRight20: isRight20,
                isRight21: isRight21,
                isRight22: isRight22,
                isRight23: isRight23,
                isRight24: isRight24,
                isRight25: isRight25,
                isRight26: isRight26,
                isRight27: isRight27,
                isRight28: isRight28
            });
            await updateDoc(doc(pathToUserData, documentId), {
                id: documentId
            });
            alert("Кол-во набранных баллов: "+totalScore+". Данные сохранены в Вашем личном кабинете");
            setIsLoading(false);
        };
    };

    const hadleCompleteExerciseSecondPart = async (e) => {
        e.preventDefault();
        setIsLoadingSecond(true);
        let date = new Date();
        const { id: documentId } = await addDoc(pathToUserData, {
            userId: user.auth.currentUser.uid,
            date: date,
            testNumber: currentTestNumber,
            question29: currentTestTwentyNinehtExAnswer,
            question30: currentTestThirtyExAnswer,
            question31: currentTestThirtyOneExAnswer,
            question32: currentTestThirtyTwoExAnswer,
            question33: currentTestThirtyThreeExAnswer,
            question34: currentTestThirtyFourExAnswer
        });
        await updateDoc(doc(pathToUserData, documentId), {
            id: documentId
        });
        alert("Ответы приняты и отправлены на проверку преподавателю")
        setIsLoadingSecond(false);
    };

    const setActiveButton = (e) => {
        var buttons = document.querySelectorAll('[data-subMenuButton]');
        for(let i = 0; i<buttons.length; i++) {
            buttons[i].classList.remove("bg-orange-700");
            buttons[i].classList.add("bg-blue-500");
        };
        e.target.classList.remove("bg-blue-500");
        e.target.classList.add("bg-orange-700");
    };

    const showTestByNumber = (e) => {
        setActiveButton(e);
        setCurrentTestNumber(parseInt(e.target.id));
        // console.log(parseInt(e.target.id.slice(-1)));
        setCurrentTestFirstEx([]);
        setCurrentTestSecondEx([]);
        setCurrentTestThirdEx([]);
        setCurrentTestFourthEx([]);
        setCurrentTestFifthEx([]);
        setCurrentTestSixthEx([]);
        setCurrentTestSeventhEx([]);
        setCurrentTestEightEx([]);
        setCurrentTestNinethEx([]);
        setCurrentTestTenthEx([]);
        setCurrentTestElevenEx([]);
        setCurrentTestTwelveEx([]);
        setCurrentTestThirteenEx([]);
        setCurrentTestFourteenEx([]);
        setCurrentTestFifteenEx([]);
        setCurrentTestSixteenEx([]);
        setCurrentTestSeventeenEx([]);
        setCurrentTestEighteenEx([]);
        setCurrentTestNineteenEx([]);
        setCurrentTestTwentyEx([]);
        setCurrentTestTwentyOneEx([]);
        setCurrentTestTwentyTwoEx([]);
        setCurrentTestTwentyThreeEx([]);
        setCurrentTestTwentyFourEx([]);
        setCurrentTestTwentyFiveEx([]);
        setCurrentTestTwentySixEx([]);
        setCurrentTestTwentySevenEx([]);
        setCurrentTestTwentyEightEx([]);
        setCurrentTestTwentyNineEx([]);
        setCurrentTestThirtyEx([]);
        setCurrentTestThirtyOneEx([]);
        setCurrentTestThirtyTwoEx([]);
        setCurrentTestThirtyThreeEx([]);
        setCurrentTestThirtyFourEx([]);

        setCurrentTestFirstExAnswer1("");
        setCurrentTestFirstExAnswer2("");
        setCurrentTestSecondExAnswer1("");
        setCurrentTestSecondExAnswer2("");
        setCurrentTestSecondExAnswer3("");
        setCurrentTestThirdExAnswer1("");
        setCurrentTestThirdExAnswer2("");
        setCurrentTestFourthExAnswer1("");
        setCurrentTestFourthExAnswer2("");
        setCurrentTestFifthExAnswer1("");
        setCurrentTestFifthExAnswer2("");
        setCurrentTestFifthExAnswer3("");
        setCurrentTestSixthExAnswer1("");
        setCurrentTestSixthExAnswer2("");
        setCurrentTestSeventhExAnswer1("");
        setCurrentTestSeventhExAnswer2("");
        setCurrentTestSeventhExAnswer3("");
        setCurrentTestSeventhExAnswer4("");
        setCurrentTestEightExAnswer1("");
        setCurrentTestEightExAnswer2("");
        setCurrentTestEightExAnswer3("");
        setCurrentTestEightExAnswer4("");
        setCurrentTestNinethExAnswer1("");
        setCurrentTestNinethExAnswer2("");
        setCurrentTestTenthExAnswer1("");
        setCurrentTestTenthExAnswer2("");
        setCurrentTestTenthExAnswer3("");
        setCurrentTestEleventhExAnswer1("");
        setCurrentTestEleventhExAnswer2("");
        setCurrentTestTwelveExAnswer("");
        setCurrentTestThirteenExAnswer1("");
        setCurrentTestThirteenExAnswer2("");
        setCurrentTestFourteenExAnswer1("");
        setCurrentTestFourteenExAnswer2("");
        setCurrentTestFourteenExAnswer3("");
        setCurrentTestFourteenExAnswer4("");
        setCurrentTestFifteenExAnswer1("");
        setCurrentTestFifteenExAnswer2("");
        setCurrentTestFifteenExAnswer3("");
        setCurrentTestFifteenExAnswer4("");
        setCurrentTestSixteenExAnswer1("");
        setCurrentTestSixteenExAnswer2("");
        setСurrentTestSeventeenExAnswer([]);
        setСurrentTestEighteenExAnswer([]);
        setCurrentTestNineteenExAnswer1("");
        setCurrentTestNineteenExAnswer2("");
        setCurrentTestNineteenExAnswer3("");
        setCurrentTestTwentyExAnswer1("");
        setCurrentTestTwentyExAnswer2("");
        setCurrentTestTwentyExAnswer3("");
        setCurrentTestTwentyOneExAnswer1("");
        setCurrentTestTwentyOneExAnswer2("");
        setCurrentTestTwentyOneExAnswer3("");
        setCurrentTestTwentyOneExAnswer4("");
        setCurrentTestTwentyTwoExAnswer1("");
        setCurrentTestTwentyTwoExAnswer2("");
        setCurrentTestTwentyTwoExAnswer3("");
        setCurrentTestTwentyTwoExAnswer4("");
        setCurrentTestTwentyThreeExAnswer1("");
        setCurrentTestTwentyThreeExAnswer2("");
        setCurrentTestTwentyFourExAnswer1("");
        setCurrentTestTwentyFourExAnswer2("");
        setCurrentTestTwentyFourExAnswer3("");
        setCurrentTestTwentyFourExAnswer4("");
        setCurrentTestTwentyFiveExAnswer1("");
        setCurrentTestTwentyFiveExAnswer2("");
        setCurrentTestTwentyFiveExAnswer3("");
        setCurrentTestTwentySixExAnswer("");
        setCurrentTestTwentySevenExAnswer("");
        setCurrentTestTwentyEightExAnswer("");
        setCurrentTestTwentyNinehtExAnswer("");
        setCurrentTestThirtyExAnswer("");
        setCurrentTestThirtyOneExAnswer("");

        data.map(values => {
            // console.log(parseInt(e.target.id.slice(-1)))
            if(parseInt(e.target.id.slice(-1)) === 1){
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 1) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestFirstEx(tempObj);
                }
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 2) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestSecondEx(tempObj);
                }
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 3) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirdEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 4) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestFourthEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 5) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, answer7: values.answer7, answer8: values.answer8, answer9: values.answer9, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestFifthEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 6) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestSixthEx(tempObj);
                };
                // if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 7) {
                //     let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                //     setCurrentTestSeventhEx(tempObj);
                // };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 7) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestSeventhEx(tempObj);
                };
                // if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 8) {
                //     let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                //     setCurrentTestEightEx(tempObj);
                // };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 8) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestEightEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 9) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestNinethEx(tempObj);
                };
                // if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 10) {
                //     let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, reagent7: values.reagent7, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                //     setCurrentTestTenthEx(tempObj);
                // };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 10) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTenthEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 11) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestElevenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 12) {
                    let tempObj = {question: values.question, questionImageRef: values.questionImageRef, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, imageFirstRef: values.imageFirstRef, imageSecondRef: values.imageSecondRef, imageThirdRef: values.imageThirdRef, imageFourthRef: values.imageFourthRef, imageFifthRef: values.imageFifthRef, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwelveEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 13) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageFifthRef: values.imageFifthRef, imageFirstRef: values.imageFirstRef, imageFourthRef: values.imageFourthRef, imageSecondRef: values.imageSecondRef, imageThirdRef: values.imageThirdRef}
                    setCurrentTestThirteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 14) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestFourteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 15) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestFifteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 16) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestSixteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 17) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestSeventeenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 18) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestEighteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 19) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestNineteenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 20) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwentyEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 21) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestTwentyOneEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 22) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwentyTwoEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 23) {
                    let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                    setCurrentTestTwentyThreeEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 24) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwentyFourEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 25) {
                    let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwentyFiveEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 26) {
                    let tempObj = {question: values.question, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                    setCurrentTestTwentySixEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 27) {
                    let tempObj = {question: values.question, imageRefTask: values.imageRefTask, imageRef: values.imageRef, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                    setCurrentTestTwentySevenEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 28) {
                    let tempObj = {question: values.question, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                    setCurrentTestTwentyEightEx(tempObj);
                };
            } else {
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 29) {
                    let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestTwentyNineEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 30) {
                    let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirtyEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 31) {
                    let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirtyOneEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 32) {
                    let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirtyTwoEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 33) {
                    let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirtyThreeEx(tempObj);
                };
                if (values.testNumber === parseInt(e.target.id.slice(0, -1)) && values.questionNumber === 34) {
                    let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber}
                    setCurrentTestThirtyFourEx(tempObj);
                };
            }
            // console.log(arrayOfObjects)
    })
    setShowButton(true);
    };

    const checkboxAnswer12 = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox12"]:checked');
        var tempArray = [];
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setCurrentTestTwelveExAnswer(tempArray);
    };

    const checkboxAnswer17 = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox17"]:checked');
        var tempArray = [];
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setСurrentTestSeventeenExAnswer(tempArray);
    };

    const checkboxAnswer18 = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox18"]:checked');
        var tempArray = [];
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setСurrentTestEighteenExAnswer(tempArray);
    };

    // Ответы на задание 1
    const [currentTestFirstExAnswer1, setCurrentTestFirstExAnswer1] = useState("");
    const [currentTestFirstExAnswer2, setCurrentTestFirstExAnswer2] = useState("");

    // Ответы на задание 2
    const [currentTestSecondExAnswer1, setCurrentTestSecondExAnswer1] = useState("");
    const [currentTestSecondExAnswer2, setCurrentTestSecondExAnswer2] = useState("");
    const [currentTestSecondExAnswer3, setCurrentTestSecondExAnswer3] = useState("");

    // Ответы на задание 3
    const [currentTestThirdExAnswer1, setCurrentTestThirdExAnswer1] = useState("");
    const [currentTestThirdExAnswer2, setCurrentTestThirdExAnswer2] = useState("");

    // Ответы на задание 4
    const [currentTestFourthExAnswer1, setCurrentTestFourthExAnswer1] = useState("");
    const [currentTestFourthExAnswer2, setCurrentTestFourthExAnswer2] = useState("");

    // Ответы на задание 5
    const [currentTestFifthExAnswer1, setCurrentTestFifthExAnswer1] = useState("");
    const [currentTestFifthExAnswer2, setCurrentTestFifthExAnswer2] = useState("");
    const [currentTestFifthExAnswer3, setCurrentTestFifthExAnswer3] = useState("");

    // Ответы на задание 6
    const [currentTestSixthExAnswer1, setCurrentTestSixthExAnswer1] = useState("");
    const [currentTestSixthExAnswer2, setCurrentTestSixthExAnswer2] = useState("");

    // Ответы на задание 7
    const [currentTestSeventhExAnswer1, setCurrentTestSeventhExAnswer1] = useState("");
    const [currentTestSeventhExAnswer2, setCurrentTestSeventhExAnswer2] = useState("");
    const [currentTestSeventhExAnswer3, setCurrentTestSeventhExAnswer3] = useState("");
    const [currentTestSeventhExAnswer4, setCurrentTestSeventhExAnswer4] = useState("");

    // Ответы на задание 8
    const [currentTestEightExAnswer1, setCurrentTestEightExAnswer1] = useState("");
    const [currentTestEightExAnswer2, setCurrentTestEightExAnswer2] = useState("");
    const [currentTestEightExAnswer3, setCurrentTestEightExAnswer3] = useState("");
    const [currentTestEightExAnswer4, setCurrentTestEightExAnswer4] = useState("");

    // Ответы на задание 9
    const [currentTestNinethExAnswer1, setCurrentTestNinethExAnswer1] = useState("");
    const [currentTestNinethExAnswer2, setCurrentTestNinethExAnswer2] = useState("");

    // Ответы на задание 10
    const [currentTestTenthExAnswer1, setCurrentTestTenthExAnswer1] = useState("");
    const [currentTestTenthExAnswer2, setCurrentTestTenthExAnswer2] = useState("");
    const [currentTestTenthExAnswer3, setCurrentTestTenthExAnswer3] = useState("");

    // Ответы на задание 11
    const [currentTestEleventhExAnswer1, setCurrentTestEleventhExAnswer1] = useState("");
    const [currentTestEleventhExAnswer2, setCurrentTestEleventhExAnswer2] = useState("");

    // Ответы на задание 12
    const [currentTestTwelveExAnswer, setCurrentTestTwelveExAnswer] = useState([]);

    // Ответы на задание 13
    const [currentTestThirteenExAnswer1, setCurrentTestThirteenExAnswer1] = useState("");
    const [currentTestThirteenExAnswer2, setCurrentTestThirteenExAnswer2] = useState("");

    // Ответы на задание 14
    const [currentTestFourteenExAnswer1, setCurrentTestFourteenExAnswer1] = useState("");
    const [currentTestFourteenExAnswer2, setCurrentTestFourteenExAnswer2] = useState("");
    const [currentTestFourteenExAnswer3, setCurrentTestFourteenExAnswer3] = useState("");
    const [currentTestFourteenExAnswer4, setCurrentTestFourteenExAnswer4] = useState("");

    // Ответы на задание 15
    const [currentTestFifteenExAnswer1, setCurrentTestFifteenExAnswer1] = useState("");
    const [currentTestFifteenExAnswer2, setCurrentTestFifteenExAnswer2] = useState("");
    const [currentTestFifteenExAnswer3, setCurrentTestFifteenExAnswer3] = useState("");
    const [currentTestFifteenExAnswer4, setCurrentTestFifteenExAnswer4] = useState("");

    // Ответы на задание 16
    const [currentTestSixteenExAnswer1, setCurrentTestSixteenExAnswer1] = useState("");
    const [currentTestSixteenExAnswer2, setCurrentTestSixteenExAnswer2] = useState("");

    // Ответы на задание 17
    const [currentTestSeventeenExAnswer, setСurrentTestSeventeenExAnswer] = useState([]);

    // Ответы на задание 18
    const [currentTestEighteenExAnswer, setСurrentTestEighteenExAnswer] = useState([]);

    // Ответы на задание 19
    const [currentTestNineteenExAnswer1, setCurrentTestNineteenExAnswer1] = useState("");
    const [currentTestNineteenExAnswer2, setCurrentTestNineteenExAnswer2] = useState("");
    const [currentTestNineteenExAnswer3, setCurrentTestNineteenExAnswer3] = useState("");

    // Ответы на задание 20
    const [currentTestTwentyExAnswer1, setCurrentTestTwentyExAnswer1] = useState("");
    const [currentTestTwentyExAnswer2, setCurrentTestTwentyExAnswer2] = useState("");
    const [currentTestTwentyExAnswer3, setCurrentTestTwentyExAnswer3] = useState("");

    // Ответы на задание 21
    const [currentTestTwentyOneExAnswer1, setCurrentTestTwentyOneExAnswer1] = useState("");
    const [currentTestTwentyOneExAnswer2, setCurrentTestTwentyOneExAnswer2] = useState("");
    const [currentTestTwentyOneExAnswer3, setCurrentTestTwentyOneExAnswer3] = useState("");
    const [currentTestTwentyOneExAnswer4, setCurrentTestTwentyOneExAnswer4] = useState("");

    // Ответы на задание 22
    const [currentTestTwentyTwoExAnswer1, setCurrentTestTwentyTwoExAnswer1] = useState("");
    const [currentTestTwentyTwoExAnswer2, setCurrentTestTwentyTwoExAnswer2] = useState("");
    const [currentTestTwentyTwoExAnswer3, setCurrentTestTwentyTwoExAnswer3] = useState("");
    const [currentTestTwentyTwoExAnswer4, setCurrentTestTwentyTwoExAnswer4] = useState("");

    // Ответы на задание 23
    const [currentTestTwentyThreeExAnswer1, setCurrentTestTwentyThreeExAnswer1] = useState("");
    const [currentTestTwentyThreeExAnswer2, setCurrentTestTwentyThreeExAnswer2] = useState("");
    
    // Ответы на задание 24
    const [currentTestTwentyFourExAnswer1, setCurrentTestTwentyFourExAnswer1] = useState("");
    const [currentTestTwentyFourExAnswer2, setCurrentTestTwentyFourExAnswer2] = useState("");
    const [currentTestTwentyFourExAnswer3, setCurrentTestTwentyFourExAnswer3] = useState("");
    const [currentTestTwentyFourExAnswer4, setCurrentTestTwentyFourExAnswer4] = useState("");

    // Ответы на задание 25
    const [currentTestTwentyFiveExAnswer1, setCurrentTestTwentyFiveExAnswer1] = useState("");
    const [currentTestTwentyFiveExAnswer2, setCurrentTestTwentyFiveExAnswer2] = useState("");
    const [currentTestTwentyFiveExAnswer3, setCurrentTestTwentyFiveExAnswer3] = useState("");

    // Ответы на задание 26
    const [currentTestTwentySixExAnswer, setCurrentTestTwentySixExAnswer] = useState("");

    // Ответы на задание 27
    const [currentTestTwentySevenExAnswer, setCurrentTestTwentySevenExAnswer] = useState("");

    // Ответы на задание 28
    const [currentTestTwentyEightExAnswer, setCurrentTestTwentyEightExAnswer] = useState("");

    // Ответы на задание 29
    const [currentTestTwentyNinehtExAnswer, setCurrentTestTwentyNinehtExAnswer] = useState("");

    // Ответы на задание 30
    const [currentTestThirtyExAnswer, setCurrentTestThirtyExAnswer] = useState("");

    // Ответы на задание 31
    const [currentTestThirtyOneExAnswer, setCurrentTestThirtyOneExAnswer] = useState("");

    // Ответы на задание 32
    const [currentTestThirtyTwoExAnswer, setCurrentTestThirtyTwoExAnswer] = useState("");

    // Ответы на задание 33
    const [currentTestThirtyThreeExAnswer, setCurrentTestThirtyThreeExAnswer] = useState("");

    // Ответы на задание 34
    const [currentTestThirtyFourExAnswer, setCurrentTestThirtyFourExAnswer] = useState("");

    // console.log(user.auth.currentUser.uid)
    // console.log(setCurrentTestTwentyEightExAnswer);

    const [messageImageUpload29, setMessageImageUpload29] = useState("Отправить фото на сервер");
    const [imageUpload29, setImageUpload29] = useState(null);
    const [messageImageUpload30, setMessageImageUpload30] = useState("Отправить фото на сервер");
    const [imageUpload30, setImageUpload30] = useState(null);
    const [messageImageUpload31, setMessageImageUpload31] = useState("Отправить фото на сервер");
    const [imageUpload31, setImageUpload31] = useState(null);
    const [messageImageUpload32, setMessageImageUpload32] = useState("Отправить фото на сервер");
    const [imageUpload32, setImageUpload32] = useState(null);
    const [messageImageUpload33, setMessageImageUpload33] = useState("Отправить фото на сервер");
    const [imageUpload33, setImageUpload33] = useState(null);
    const [messageImageUpload34, setMessageImageUpload34] = useState("Отправить фото на сервер");
    const [imageUpload34, setImageUpload34] = useState(null);

    const uploadImage29 = (e) => {
        e.preventDefault();
        if(imageUpload29 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"29_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload29).then(() => {
            setMessageImageUpload29("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestTwentyNinehtExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    const uploadImage30 = (e) => {
        e.preventDefault();
        if(imageUpload30 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"30_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload30).then(() => {
            setMessageImageUpload30("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestThirtyExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    const uploadImage31 = (e) => {
        e.preventDefault();
        if(imageUpload31 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"31_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload31).then(() => {
            setMessageImageUpload31("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestThirtyOneExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    const uploadImage32 = (e) => {
        e.preventDefault();
        if(imageUpload32 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"32_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload32).then(() => {
            setMessageImageUpload32("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestThirtyTwoExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    const uploadImage33 = (e) => {
        e.preventDefault();
        if(imageUpload33 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"33_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload33).then(() => {
            setMessageImageUpload33("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestThirtyThreeExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    const uploadImage34 = (e) => {
        e.preventDefault();
        if(imageUpload34 == null) return;
        const imageRef = ref(storage, `userTaskImageBase/${"34_"+user.auth.currentUser.uid+"_" + v4()}`)
        uploadBytes(imageRef, imageUpload34).then(() => {
            setMessageImageUpload34("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setCurrentTestThirtyFourExAnswer(url);
            });
        });
        e.target.classList.remove("bg-gray-300");
        e.target.classList.remove("hover:bg-yellow-400");
        e.target.classList.add("bg-green-500");
    };

    function sortAnswerArrays(x, y){
        var pre = ['string' , 'number' , 'bool']
        if(typeof x!== typeof y )return pre.indexOf(typeof y) - pre.indexOf(typeof x);
    
        if(x === y)return 0;
        else return (x > y)?1:-1;
    };

    const checkAllAnswers = () => {
        var totalScore = 0;

    // Задание 1
        let question1 = document.getElementById(currentTestFirstEx.id);

        var userAnswer1 = [currentTestFirstExAnswer1, currentTestFirstExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer1 = currentTestFirstEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer1 === rightAnswer1){
            question1.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight1 = false;
            question1.classList.add("ring-red-500");
        };

    // Задание 2
        let question2 = document.getElementById(currentTestSecondEx.id);

        var userAnswer2 = [currentTestSecondExAnswer1, currentTestSecondExAnswer2, currentTestSecondExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer2 = currentTestSecondEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer2 === rightAnswer2){
            question2.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight2 = false;
            question2.classList.add("ring-red-500");
        };
    
    // Задание 3
        let question3 = document.getElementById(currentTestThirdEx.id);

        var userAnswer3 = [currentTestThirdExAnswer1, currentTestThirdExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer3 = currentTestThirdEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer3 === rightAnswer3){
            question3.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight3 = false;
            question3.classList.add("ring-red-500");
        };

    // Задание 4
        let question4 = document.getElementById(currentTestFourthEx.id);

        var userAnswer4 = [currentTestFourthExAnswer1, currentTestFourthExAnswer1].sort(sortAnswerArrays).toString();
        var rightAnswer4 = currentTestFourthEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer4 === rightAnswer4){
            question4.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight4 = false;
            question4.classList.add("ring-red-500");
        };

    // Задание 5
        let question5 = document.getElementById(currentTestFifthEx.id);

        var userAnswer5 = [currentTestFifthExAnswer1, currentTestFifthExAnswer2, currentTestFifthExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer5 = currentTestFifthEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer5 === rightAnswer5){
            question5.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight5 = false;
            question5.classList.add("ring-red-500");
        };

    // Задание 6
        let question6 = document.getElementById(currentTestSixthEx.id);

        var tempScore6 = 0; 

        switch(currentTestSixthExAnswer1) {
            case currentTestSixthEx.rightAnswer[0]:
                totalScore += 1;
                tempScore6 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestSixthExAnswer2) {
            case currentTestSixthEx.rightAnswer[1]:
                totalScore += 1;
                tempScore6 += 1;
                break;
            default:
                console.log("no matches")
        }

        if(tempScore6 === 0){
            isRight6 = false;
            question6.classList.add("ring-red-500");
        } else if(tempScore6 === 1) {
            isRight6 = false;
            question6.classList.add("ring-orange-500");
        } else {
            question6.classList.add("ring-green-500");
        };

    // Задание 7
        // let question7 = document.getElementById(currentTestSeventhEx.id);

        // var tempScore7 = 0; 

        // switch(currentTestSeventhExAnswer1) {
        //     case currentTestSeventhEx.rightAnswer[0]:
        //         tempScore7 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestSeventhExAnswer2) {
        //     case currentTestSeventhEx.rightAnswer[1]:
        //         tempScore7 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestSeventhExAnswer3) {
        //     case currentTestSeventhEx.rightAnswer[2]:
        //         tempScore7 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestSeventhExAnswer4) {
        //     case currentTestSeventhEx.rightAnswer[3]:
        //         tempScore7 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }
        
        // if(tempScore7 === 4){
        //     totalScore += 2;
        //     question7.classList.add("ring-green-500");
        // } else if(tempScore7 === 3) {
        //     totalScore += 1;
        //     isRight7 = false;
        //     question7.classList.add("ring-orange-500");
        // } else {
        //     isRight7 = false;
        //     question7.classList.add("ring-red-500");
        // };
    
    // Задание 7
        let question7 = document.getElementById(currentTestSeventhEx.id);

        var tempScore7 = 0; 

        switch(currentTestSeventhExAnswer1) {
            case currentTestSeventhEx.rightAnswer[0]:
                tempScore7 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestSeventhExAnswer2) {
            case currentTestSeventhEx.rightAnswer[1]:
                tempScore7 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestSeventhExAnswer3) {
            case currentTestSeventhEx.rightAnswer[2]:
                tempScore7 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestSeventhExAnswer4) {
            case currentTestSeventhEx.rightAnswer[3]:
                tempScore7 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore7 === 4){
            totalScore += 2;
            question7.classList.add("ring-green-500");
        } else if(tempScore7 === 3) {
            totalScore += 1;
            isRight7 = false;
            question7.classList.add("ring-orange-500");
        } else {
            isRight7 = false;
            question7.classList.add("ring-red-500");
        };

    // Задание 8
        // let question8 = document.getElementById(currentTestEightEx.id);

        // var tempScore8 = 0; 

        // switch(currentTestEightExAnswer1) {
        //     case currentTestEightEx.rightAnswer[0]:
        //         tempScore8 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestEightExAnswer2) {
        //     case currentTestEightEx.rightAnswer[1]:
        //         tempScore8 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestEightExAnswer3) {
        //     case currentTestEightEx.rightAnswer[2]:
        //         tempScore8 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }

        // switch(currentTestEightExAnswer4) {
        //     case currentTestEightEx.rightAnswer[3]:
        //         tempScore8 += 1;
        //         break;
        //     default:
        //         console.log("no matches")
        // }
        
        // if(tempScore8 === 4){
        //     totalScore += 2;
        //     question8.classList.add("ring-green-500");
        // } else if(tempScore8 === 3) {
        //     totalScore += 1;
        //     isRight8 = false;
        //     question8.classList.add("ring-orange-500");
        // } else {
        //     isRight8 = false;
        //     question8.classList.add("ring-red-500");
        // };
        let question8 = document.getElementById(currentTestEightEx.id);

        var tempScore8 = 0; 

        switch(currentTestEightExAnswer1) {
            case currentTestEightEx.rightAnswer[0]:
                tempScore8 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestEightExAnswer2) {
            case currentTestEightEx.rightAnswer[1]:
                tempScore8 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestEightExAnswer3) {
            case currentTestEightEx.rightAnswer[2]:
                tempScore8 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestEightExAnswer4) {
            case currentTestEightEx.rightAnswer[3]:
                tempScore8 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore8 === 4){
            totalScore += 2;
            question8.classList.add("ring-green-500");
        } else if(tempScore8 === 3) {
            totalScore += 1;
            isRight8 = false;
            question8.classList.add("ring-orange-500");
        } else {
            isRight8 = false;
            question8.classList.add("ring-red-500");
        };

    // Задание 9
        let question9 = document.getElementById(currentTestNinethEx.id);

        var userAnswer9 = [currentTestNinethExAnswer1, currentTestNinethExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer9 = currentTestNinethEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer9 === rightAnswer9){
            question9.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight9 = false;
            question9.classList.add("ring-red-500");
        };

    // Задание 10
        let question10 = document.getElementById(currentTestTenthEx.id);

        var userAnswer10 = [currentTestTenthExAnswer1, currentTestTenthExAnswer2, currentTestTenthExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer10 = currentTestTenthEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer10 === rightAnswer10){
            question10.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight10 = false;
            question10.classList.add("ring-red-500");
        };

    // Задание 11
        let question11 = document.getElementById(currentTestElevenhtEx.id);

        var userAnswer11 = [currentTestEleventhExAnswer1, currentTestEleventhExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer11 = currentTestElevenhtEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer11 === rightAnswer11){
            question11.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight11 = false;
            question11.classList.add("ring-red-500");
        };

    // Задание 12
        let question12 = document.getElementById(currentTestTwelveEx.id);

        var userAnswer12 = currentTestTwelveExAnswer.sort(sortAnswerArrays).toString();
        var rightAnswer12 = currentTestTwelveEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer12 === rightAnswer12){
            question12.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight12 = false;
            question12.classList.add("ring-red-500");
        };

    // Задание 13
        let question13 = document.getElementById(currentTestThirteenEx.id);

        var userAnswer13 = [currentTestThirteenExAnswer1, currentTestThirteenExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer13 = currentTestThirteenEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer13 === rightAnswer13){
            question13.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight13 = false;
            question13.classList.add("ring-red-500");
        };

    // Задание 14
        let question14 = document.getElementById(currentTestFourteenEx.id);

        var tempScore14 = 0; 

        switch(currentTestFourteenExAnswer1) {
            case currentTestFourteenEx.rightAnswer[0]:
                tempScore14 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFourteenExAnswer2) {
            case currentTestFourteenEx.rightAnswer[1]:
                tempScore14 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFourteenExAnswer3) {
            case currentTestFourteenEx.rightAnswer[2]:
                tempScore14 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFourteenExAnswer4) {
            case currentTestFourteenEx.rightAnswer[3]:
                tempScore14 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore14 === 4){
            totalScore += 2;
            question14.classList.add("ring-green-500");
        } else if(tempScore14 === 3) {
            totalScore += 1;
            isRight14 = false;
            question14.classList.add("ring-orange-500");
        } else {
            isRight14 = false;
            question14.classList.add("ring-red-500");
        };

    // Задание 15
        let question15 = document.getElementById(currentTestFifteenEx.id);

        var tempScore15 = 0; 

        switch(currentTestFifteenExAnswer1) {
            case currentTestFifteenEx.rightAnswer[0]:
                tempScore15 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFifteenExAnswer2) {
            case currentTestFifteenEx.rightAnswer[1]:
                tempScore15 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFifteenExAnswer3) {
            case currentTestFifteenEx.rightAnswer[2]:
                tempScore15 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestFifteenExAnswer4) {
            case currentTestFifteenEx.rightAnswer[3]:
                tempScore15 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore15 === 4){
            totalScore += 2;
            question15.classList.add("ring-green-500");
        } else if(tempScore15 === 3) {
            totalScore += 1;
            isRight15 = false;
            question15.classList.add("ring-orange-500");
        } else {
            isRight15 = false;
            question15.classList.add("ring-red-500");
        };

    // Задание 16
        let question16 = document.getElementById(currentTestSixteenEx.id);

        var userAnswer16 = [currentTestSixteenExAnswer1, currentTestSixteenExAnswer2].sort(sortAnswerArrays).toString();
        var rightAnswer16 = currentTestSixteenEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer16 === rightAnswer16){
            question16.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight16 = false;
            question16.classList.add("ring-red-500");
        };

    // Задание 17
        let question17 = document.getElementById(currentTestSeventeenEx.id);

        var userAnswer17 = currentTestSeventeenExAnswer.sort(sortAnswerArrays).toString();
        var rightAnswer17 = currentTestSeventeenEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer17 === rightAnswer17){
            question17.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight17 = false;
            question17.classList.add("ring-red-500");
        };

    // Задание 18
        let question18 = document.getElementById(currentTestEighteenEx.id);

        var userAnswer18 = currentTestEighteenExAnswer.sort(sortAnswerArrays).toString();
        var rightAnswer18 = currentTestEighteenEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer18 === rightAnswer18){
            question18.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight18 = false;
            question18.classList.add("ring-red-500");
        };

    // Задание 19
        let question19 = document.getElementById(currentTestNineteenEx.id);

        var userAnswer19 = [currentTestNineteenExAnswer1, currentTestNineteenExAnswer2, currentTestNineteenExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer19 = currentTestNineteenEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer19 === rightAnswer19){
            question19.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight19 = false;
            question19.classList.add("ring-red-500");
        };

    // Задание 20
        let question20 = document.getElementById(currentTestTwentyEx.id);

        var userAnswer20 = [currentTestTwentyExAnswer1, currentTestTwentyExAnswer2, currentTestTwentyExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer20 = currentTestTwentyEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer20 === rightAnswer20){
            question20.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight20 = false;
            question20.classList.add("ring-red-500");
        };

    // Задание 21
        let question21 = document.getElementById(currentTestTwentyOneEx.id);

        var userAnswer21 = [currentTestTwentyOneExAnswer1, currentTestTwentyOneExAnswer2, currentTestTwentyOneExAnswer3, currentTestTwentyOneExAnswer4].sort(sortAnswerArrays).toString();
        var rightAnswer21 = currentTestTwentyOneEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer21 === rightAnswer21){
            question21.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight21 = false;
            question21.classList.add("ring-red-500");
        };

    // Задание 22
        let question22 = document.getElementById(currentTestTwentyTwoEx.id);

        var tempScore22 = 0; 

        switch(currentTestTwentyTwoExAnswer1) {
            case currentTestTwentyTwoEx.rightAnswer[0]:
                tempScore22 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyTwoExAnswer2) {
            case currentTestTwentyTwoEx.rightAnswer[1]:
                tempScore22 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyTwoExAnswer3) {
            case currentTestTwentyTwoEx.rightAnswer[2]:
                tempScore22 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyTwoExAnswer4) {
            case currentTestTwentyTwoEx.rightAnswer[3]:
                tempScore22 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore22 === 4){
            totalScore += 2;
            question22.classList.add("ring-green-500");
        } else if(tempScore22 === 3) {
            totalScore += 1;
            isRight22 = false;
            question22.classList.add("ring-orange-500");
        } else {
            isRight22 = false;
            question22.classList.add("ring-red-500");
        };

    // Задание 23
        let question23 = document.getElementById(currentTestTwentyThreeEx.id);

        var tempScore23 = 0; 

        switch(currentTestTwentyThreeExAnswer1) {
            case currentTestTwentyThreeEx.rightAnswer[0]:
                totalScore += 1;
                tempScore23 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyThreeExAnswer2) {
            case currentTestTwentyThreeEx.rightAnswer[1]:
                totalScore += 1;
                tempScore23 += 1;
                break;
            default:
                console.log("no matches")
        }

        if(tempScore23 === 0){
            question23.classList.add("ring-red-500");
        } else if(tempScore23 === 1) {
            isRight23 = false;
            question23.classList.add("ring-orange-500");
        } else {
            isRight23 = false;
            question23.classList.add("ring-green-500");
        };

    // Задание 24
        let question24 = document.getElementById(currentTestTwentyFourEx.id);

        var tempScore24 = 0; 

        switch(currentTestTwentyFourExAnswer1) {
            case currentTestTwentyFourEx.rightAnswer[0]:
                tempScore24 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyFourExAnswer2) {
            case currentTestTwentyFourEx.rightAnswer[1]:
                tempScore24 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyFourExAnswer3) {
            case currentTestTwentyFourEx.rightAnswer[2]:
                tempScore24 += 1;
                break;
            default:
                console.log("no matches")
        }

        switch(currentTestTwentyFourExAnswer4) {
            case currentTestTwentyFourEx.rightAnswer[3]:
                tempScore24 += 1;
                break;
            default:
                console.log("no matches")
        }
        
        if(tempScore24 === 4){
            totalScore += 2;
            question24.classList.add("ring-green-500");
        } else if(tempScore24 === 3) {
            totalScore += 1;
            isRight24 = false;
            question24.classList.add("ring-orange-500");
        } else {
            isRight24 = false;
            question24.classList.add("ring-red-500");
        };

    // Задание 25
        let question25 = document.getElementById(currentTestTwentyFiveEx.id);

        var userAnswer25 = [currentTestTwentyFiveExAnswer1, currentTestTwentyFiveExAnswer2, currentTestTwentyFiveExAnswer3].sort(sortAnswerArrays).toString();
        var rightAnswer25 = currentTestTwentyFiveEx.rightAnswer.sort(sortAnswerArrays).toString();

        if(userAnswer25 === rightAnswer25){
            question25.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight25 = false;
            question25.classList.add("ring-red-500");
        };

    // Задание 26
        let question26 = document.getElementById(currentTestTwentySixEx.id);

        var userAnswer26 = currentTestTwentySixExAnswer;
        var rightAnswer26 = currentTestTwentySixEx.rightAnswer;

        if(userAnswer26 === rightAnswer26){
            question26.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight26 = false;
            question26.classList.add("ring-red-500");
        };

    // Задание 27
        let question27 = document.getElementById(currentTestTwentySevenEx.id);

        var userAnswer27 = currentTestTwentySevenExAnswer;
        var rightAnswer27 = currentTestTwentySevenEx.rightAnswer;

        if(userAnswer27 === rightAnswer27){
            question27.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight27 = false;
            question27.classList.add("ring-red-500");
        };

    // Задание 28
        let question28 = document.getElementById(currentTestTwentyEightEx.id);

        var userAnswer28 = currentTestTwentyEightExAnswer;
        var rightAnswer28 = currentTestTwentyEightEx.rightAnswer;

        if(userAnswer28 === rightAnswer28){
            question28.classList.add("ring-green-500");
            totalScore += 1;
        } else {
            isRight28 = false;
            question28.classList.add("ring-red-500");
        };

        // console.log(totalScore);
        return totalScore;
    }

const generatePDF = () => {
    const input = document.getElementById('canvas-container'); // замените на ID элемента, который нужно захватить

    html2canvas(input, { scrollY: -window.scrollY, useCORS: true, allowTaint: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const imgHeight = (pdfWidth / canvasWidth) * canvasHeight;

        let heightLeft = imgHeight;
        let position = 0;

        // Добавляем изображение и разбиваем его на страницы
        while (heightLeft > 0) {
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;
            position -= pdfHeight;

            if (heightLeft > 0) {
                pdf.addPage();
            }
        }

        // Добавляем водяной знак на каждую страницу
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(30);
            pdf.setTextColor(150, 150, 150); // светло-серый цвет

            // Определяем количество строк и столбцов для равномерного размещения
            const rows = 6;  // 6 строк
            const cols = 5;  // 5 столбцов
            const xMargin = 20; // Отступ по горизонтали
            const yMargin = 30; // Отступ по вертикали

            // Шаги по X и Y для равномерного размещения
            const xStep = (pdfWidth - 2 * xMargin) / (cols - 1);
            const yStep = (pdfHeight - 2 * yMargin) / (rows - 1);

            // Цикл для добавления водяных знаков
            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    const x = xMargin + col * xStep;
                    const y = yMargin + row * yStep;
                    pdf.saveGraphicsState();
                    pdf.setGState(new pdf.GState({ opacity: 0.2 }));
                    pdf.text("him100test.ru", x, y, { angle: 45 });
                    pdf.restoreGraphicsState();
                }
            }
        }

        // Сохраняем PDF
        pdf.save('document.pdf');
    });
};

    
    // console.log(currentUserSubsInfo);
    const [userAnswers, setUserAnswers] = useState([]);

    useEffect(()=> {
        var array = [];
        const pathToTheUserAnswers = collection(database, "userAnswers");
        
        const getData = async() => {
            const dbData = await getDocs(pathToTheUserAnswers)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            receivedData.map(values => (
                values.userId === user.uid ? array.push(values.testNumber) : ""
            ));
            const uniqueArray = [...new Set(array)];
            setUserAnswers(uniqueArray);
        }
        getData()

    },[]);
    // console.log(userAnswers)
    // disabled={userAnswers.includes(parseInt(number))}
    return (
        <div>
            <section className="bg-gray-200 py-1 px-2">
                <h3 className="mb-2 mt-0 text-2xl font-medium leading-tight text-primary">Выберите вариант теста</h3>
            </section>
            <section className="bg-gray-200 py-1 space-x-1 pb-3 px-2">
                {currentUserSubsInfo !== "" && currentUserSubsInfo.subscriptionUntill.toDate() > new Date() ?
                    numberOfTests.sort().map((number) => <button key={number} onClick={showTestByNumber} data-subMenuButton="number" id={number} className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline disabled:bg-slate-400" type="button">
                        Вариант № {number[0]} {number[1] ? "(ч. "+number[1]+")" : ""}
                    </button>)
                : <div className="flex flex-row overflow-x-auto"><button onClick={showTestByNumber} data-subMenuButton="number" id="11" className="w-36 mb-1 mt-1 ml-1 ring-1 ring-black bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-8 rounded-l focus:outline-none focus:shadow-outline" type="button">
                    Вариант № 1 (ч. 1)
                </button><button onClick={showTestByNumber} data-subMenuButton="number" id="12" className="w-36 mb-1 mt-1 mr-1 ring-1 ring-black bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-8 rounded-r focus:outline-none focus:shadow-outline" type="button">
                    Вариант № 1 (ч. 2)
                </button></div>}
                <div className={user.uid === "VSseTT8ucpQhrB4x7emzAqb9NxP2" ? "" : "hidden"}>
                    <button onClick={generatePDF} className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline">
                        Скачать PDF
                    </button>
                </div>
            </section>
            <section className="bg-gray-200 py-4 flex flex-col items-center pl-1 pr-1" id="canvas-container">
{/* 1 */}
                {currentTestFirstEx.length !== 0 ?
                    <section key={currentTestFirstEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestFirstEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 1</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestFirstEx.question}</h3>
                    <div className="flex flex-row space-x-5 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestFirstEx.answer1}</p>
                        <p>2) {currentTestFirstEx.answer2}</p>
                        <p>3) {currentTestFirstEx.answer3}</p>
                        <p>4) {currentTestFirstEx.answer4}</p>
                        <p>5) {currentTestFirstEx.answer5}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestFirstExAnswer1} onChange={(e) => setCurrentTestFirstExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestFirstExAnswer2} onChange={(e) => setCurrentTestFirstExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 2 */}
                {currentTestSecondEx.length !== 0 ?
                    <section key={currentTestSecondEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSecondEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 2</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSecondEx.question}</h3>
                    <div className="flex flex-row space-x-5 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestSecondEx.answer1}</p>
                        <p>2) {currentTestSecondEx.answer2}</p>
                        <p>3) {currentTestSecondEx.answer3}</p>
                        <p>4) {currentTestSecondEx.answer4}</p>
                        <p>5) {currentTestSecondEx.answer5}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestSecondExAnswer1} onChange={(e) => setCurrentTestSecondExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestSecondExAnswer2} onChange={(e) => setCurrentTestSecondExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestSecondExAnswer3} onChange={(e) => setCurrentTestSecondExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 3 */}
                {currentTestThirdEx.length !== 0 ?
                    <section key={currentTestThirdEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestThirdEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 3</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestThirdEx.question}</h3>
                    <div className="flex flex-row space-x-5 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestThirdEx.answer1}</p>
                        <p>2) {currentTestThirdEx.answer2}</p>
                        <p>3) {currentTestThirdEx.answer3}</p>
                        <p>4) {currentTestThirdEx.answer4}</p>
                        <p>5) {currentTestThirdEx.answer5}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestThirdExAnswer1} onChange={(e) => setCurrentTestThirdExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestThirdExAnswer2} onChange={(e) => setCurrentTestThirdExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 4 */}
                {currentTestFourthEx.length !== 0 ?
                    <section key={currentTestFourthEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestFourthEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 4</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestFourthEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row space-x-0 sm:space-x-5 md:space-x-5 lg:space-x-5 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestFourthEx.answer1}</p>
                        <p>2) {currentTestFourthEx.answer2}</p>
                        <p>3) {currentTestFourthEx.answer3}</p>
                        <p>4) {currentTestFourthEx.answer4}</p>
                        <p>5) {currentTestFourthEx.answer5}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestFourthExAnswer1} onChange={(e) => setCurrentTestFourthExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestFourthExAnswer2} onChange={(e) => setCurrentTestFourthExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 5 */}
                {currentTestFifthEx.length !== 0 ?
                    <section key={currentTestFifthEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestFifthEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 5</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestFifthEx.question}</h3>
                    <div className="flex justify-start">
                        <table className="border-collapse border border-black text-center w-full md:w-auto sm:w-auto lg:w-auto mb-3 mt-3">
                            <tr>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">1</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer1)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">2</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer2)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">3</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer3)}}></p>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">4</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer4)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">5</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer5)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">6</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer6)}}></p>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">7</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer7)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">8</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer8)}}></p>
                                </td>
                                <td className="border border-black text-center w-1/3">
                                    <p className="text-left font-black">9</p>
                                    <p className="mb-5" dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifthEx.answer9)}}></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifthExAnswer1} onChange={(e) => setCurrentTestFifthExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifthExAnswer2} onChange={(e) => setCurrentTestFifthExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifthExAnswer3} onChange={(e) => setCurrentTestFifthExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 6 */}
                {currentTestSixthEx.length !== 0 ?
                    <section key={currentTestSixthEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSixthEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 6</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSixthEx.question}</h3>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSixthEx.answer1)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSixthEx.answer2)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSixthEx.answer3)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSixthEx.answer4)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSixthEx.answer5)}}></p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">X</td>
                                <td className="border border-black text-center pb-2">Y</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestSixthExAnswer1} onChange={(e) => setCurrentTestSixthExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSixthExAnswer2} onChange={(e) => setCurrentTestSixthExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 7 */}
                {/* {currentTestSeventhEx.length !== 0 ?
                    <section key={currentTestSeventhEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSeventhEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 7</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSeventhEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">Вещества</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestSeventhEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestSeventhEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestSeventhEx.substance3)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestSeventhEx.substance4)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">Реагенты</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSeventhEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSeventhEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSeventhEx.reagent3)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSeventhEx.reagent4)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSeventhEx.reagent5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer1} onChange={(e) => setCurrentTestSeventhExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer2} onChange={(e) => setCurrentTestSeventhExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer3} onChange={(e) => setCurrentTestSeventhExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer4} onChange={(e) => setCurrentTestSeventhExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""} */}
                {currentTestSeventhEx.length !== 0 ?
                    <section key={currentTestSeventhEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSeventhEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 7</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSeventhEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestSeventhEx.nameOfSubstance}</h3>
                            {currentTestSeventhEx.imageFirstRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">А)<img src={currentTestSeventhEx.imageFirstRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestSeventhEx.substance1)}}></p>}
                            {currentTestSeventhEx.imageSecondRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestSeventhEx.imageSecondRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestSeventhEx.substance2)}}></p>}
                            {currentTestSeventhEx.imageThirdRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">В)<img src={currentTestSeventhEx.imageThirdRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestSeventhEx.substance3)}}></p>}
                            {currentTestSeventhEx.imageFourthRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Г)<img src={currentTestSeventhEx.imageFourthRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestSeventhEx.substance4)}}></p>}
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center font-semibold">{currentTestSeventhEx.nameOfReagent}</h3>
                            {currentTestSeventhEx.imageFirstRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestSeventhEx.imageFirstRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSeventhEx.reagent1)}}></p>}
                            {currentTestSeventhEx.imageSecondRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestSeventhEx.imageSecondRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSeventhEx.reagent2)}}></p>}
                            {currentTestSeventhEx.imageThirdRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">3)<img src={currentTestSeventhEx.imageThirdRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSeventhEx.reagent3)}}></p>}
                            {currentTestSeventhEx.imageFourthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">4)<img src={currentTestSeventhEx.imageFourthRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSeventhEx.reagent4)}}></p>}
                            {currentTestSeventhEx.imageFifthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">5)<img src={currentTestSeventhEx.imageFifthRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSeventhEx.reagent5)}}></p>}
                            {currentTestSeventhEx.reagent6 && currentTestSeventhEx.imageSixRefReagent !== "" ? currentTestSeventhEx.imageSixRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">6)<img src={currentTestSeventhEx.imageSixRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "6) "+containsNumber(currentTestSeventhEx.reagent6)}}></p> : ""}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer1} onChange={(e) => setCurrentTestSeventhExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer2} onChange={(e) => setCurrentTestSeventhExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer3} onChange={(e) => setCurrentTestSeventhExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSeventhExAnswer4} onChange={(e) => setCurrentTestSeventhExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 8 */}
                {/* {currentTestEightEx.length !== 0 ?
                    <section key={currentTestEightEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestEightEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 8</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestEightEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">Исходные вещества</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestEightEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestEightEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestEightEx.substance3)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestEightEx.substance4)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">Продукты реакции</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestEightEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestEightEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestEightEx.reagent3)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestEightEx.reagent4)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestEightEx.reagent5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer1} onChange={(e) => setCurrentTestEightExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer2} onChange={(e) => setCurrentTestEightExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer3} onChange={(e) => setCurrentTestEightExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer4} onChange={(e) => setCurrentTestEightExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""} */}
                {currentTestEightEx.length !== 0 ?
                    <section key={currentTestEightEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestEightEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 8</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestEightEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestEightEx.nameOfSubstance}</h3>
                            {currentTestEightEx.imageFirstRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">А)<img src={currentTestEightEx.imageFirstRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestEightEx.substance1)}}></p>}
                            {currentTestEightEx.imageSecondRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestEightEx.imageSecondRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestEightEx.substance2)}}></p>}
                            {currentTestEightEx.imageThirdRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">В)<img src={currentTestEightEx.imageThirdRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestEightEx.substance3)}}></p>}
                            {currentTestEightEx.imageFourthRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Г)<img src={currentTestEightEx.imageFourthRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestEightEx.substance4)}}></p>}
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center font-semibold">{currentTestEightEx.nameOfReagent}</h3>
                            {currentTestEightEx.imageFirstRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestEightEx.imageFirstRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestEightEx.reagent1)}}></p>}
                            {currentTestEightEx.imageSecondRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestEightEx.imageSecondRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestEightEx.reagent2)}}></p>}
                            {currentTestEightEx.imageThirdRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">3)<img src={currentTestEightEx.imageThirdRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestEightEx.reagent3)}}></p>}
                            {currentTestEightEx.imageFourthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">4)<img src={currentTestEightEx.imageFourthRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestEightEx.reagent4)}}></p>}
                            {currentTestEightEx.imageFifthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">5)<img src={currentTestEightEx.imageFifthRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestEightEx.reagent5)}}></p>}
                            {currentTestEightEx.imageSixRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">6)<img src={currentTestEightEx.imageSixRefReagent} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "6) "+containsNumber(currentTestEightEx.reagent6)}}></p>}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer1} onChange={(e) => setCurrentTestEightExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer2} onChange={(e) => setCurrentTestEightExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer3} onChange={(e) => setCurrentTestEightExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestEightExAnswer4} onChange={(e) => setCurrentTestEightExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 9 */}
                {currentTestNinethEx.length !== 0 ?
                    <section key={currentTestNinethEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestNinethEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 9</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestNinethEx.question}</h3>
                    <div className="flex justify-center">
                        <img src={currentTestNinethEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestNinethEx.answer1)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestNinethEx.answer2)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestNinethEx.answer3)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestNinethEx.answer4)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestNinethEx.answer5)}}></p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">X</td>
                                <td className="border border-black text-center pb-2">Y</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestNinethExAnswer1} onChange={(e) => setCurrentTestNinethExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestNinethExAnswer2} onChange={(e) => setCurrentTestNinethExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 10 */}
                {/* {currentTestTenthEx.length !== 0 ?
                    <section key={currentTestTenthEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTenthEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 10</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTenthEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">Исходные вещества</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTenthEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTenthEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTenthEx.substance3)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">Продукты реакции</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTenthEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTenthEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTenthEx.reagent3)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTenthEx.reagent4)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTenthEx.reagent5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer1} onChange={(e) => setCurrentTestTenthExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer2} onChange={(e) => setCurrentTestTenthExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer3} onChange={(e) => setCurrentTestTenthExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""} */}
                {currentTestTenthEx.length !== 0 ?
                    <section key={currentTestTenthEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTenthEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 10</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTenthEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestTenthEx.nameOfSubstance}</h3>
                            {currentTestTenthEx.imageFirstRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">А)<img src={currentTestTenthEx.imageFirstRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTenthEx.substance1)}}></p>}
                            {currentTestTenthEx.imageSecondRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestTenthEx.imageSecondRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTenthEx.substance2)}}></p>}
                            {currentTestTenthEx.imageThirdRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">В)<img src={currentTestTenthEx.imageThirdRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTenthEx.substance3)}}></p>}
                            {currentTestTenthEx.substance4 && currentTestTenthEx.imageFourthRefSubstance !== "" ? currentTestTenthEx.imageFourthRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Г)<img src={currentTestTenthEx.imageFourthRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestTenthEx.substance4)}}></p> : ""}
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center font-semibold">{currentTestTenthEx.nameOfReagent}</h3>
                            {currentTestTenthEx.imageFirstRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestTenthEx.imageFirstRefReagent} className="h-auto w-72" /></p> : <p>1) {currentTestTenthEx.reagent1}</p>}
                            {currentTestTenthEx.imageSecondRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestTenthEx.imageSecondRefReagent} className="h-auto w-72" /></p> : <p>2) {currentTestTenthEx.reagent2}</p>}
                            {currentTestTenthEx.imageThirdRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">3)<img src={currentTestTenthEx.imageThirdRefReagent} className="h-auto w-72" /></p> : <p>3) {currentTestTenthEx.reagent3}</p>}
                            {currentTestTenthEx.imageFourthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">4)<img src={currentTestTenthEx.imageFourthRefReagent} className="h-auto w-72" /></p> : <p>4) {currentTestTenthEx.reagent4}</p>}
                            {currentTestTenthEx.reagent5 && currentTestTenthEx.imageFifthRefReagent !== "" ? currentTestTenthEx.imageFifthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">5)<img src={currentTestTenthEx.imageFifthRefReagent} className="h-auto w-72" /></p> : <p>5) {currentTestTenthEx.reagent5}</p> : ""}
                            {currentTestTenthEx.reagent6 && currentTestTenthEx.imageSixRefReagent !== "" ? currentTestTenthEx.imageSixRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">6)<img src={currentTestTenthEx.imageSixRefReagent} className="h-auto w-72" /></p> : <p>6) {currentTestTenthEx.reagent6}</p> : ""}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer1} onChange={(e) => setCurrentTestTenthExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer2} onChange={(e) => setCurrentTestTenthExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTenthExAnswer3} onChange={(e) => setCurrentTestTenthExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 11 */}
                {currentTestElevenhtEx.length !== 0 ?
                    <section key={currentTestElevenhtEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestElevenhtEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 11</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestElevenhtEx.question}</h3>
                    <div className="flex flex-col space-y-1 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestElevenhtEx.answer1}</p>
                        <p>2) {currentTestElevenhtEx.answer2}</p>
                        <p>3) {currentTestElevenhtEx.answer3}</p>
                        <p>4) {currentTestElevenhtEx.answer4}</p>
                        <p>5) {currentTestElevenhtEx.answer5}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestEleventhExAnswer1} onChange={(e) => setCurrentTestEleventhExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestEleventhExAnswer2} onChange={(e) => setCurrentTestEleventhExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 12 */}
                {currentTestTwelveEx.length !== 0 ?
                    <section key={currentTestTwelveEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwelveEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 12</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwelveEx.question}</h3>
                    <div className="flex justify-start">
                        <img src={currentTestTwelveEx.questionImageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer12} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox12" />
                            {currentTestTwelveEx.imageFirstRef !== "" ? <p className="flex flex-row items-center">1)<img src={currentTestTwelveEx.imageFirstRef} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwelveEx.answer1)}}></p>}
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer12} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox12" />
                            {currentTestTwelveEx.imageSecondRef !== "" ? <p className="flex flex-row items-center">2)<img src={currentTestTwelveEx.imageSecondRef} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwelveEx.answer2)}}></p>}
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer12} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox12" />
                            {currentTestTwelveEx.imageThirdRef !== "" ? <p className="flex flex-row items-center">3)<img src={currentTestTwelveEx.imageThirdRef} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwelveEx.answer3)}}></p>}
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer12} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox12" />
                            {currentTestTwelveEx.imageFourthRef !== "" ? <p className="flex flex-row items-center">4)<img src={currentTestTwelveEx.imageFourthRef} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTwelveEx.answer4)}}></p>}
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer12} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox12" />
                            {currentTestTwelveEx.imageFifthRef !== "" ? <p className="flex flex-row items-center">5)<img src={currentTestTwelveEx.imageFifthRef} className="h-auto w-72" /></p> : <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTwelveEx.answer5)}}></p>}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ: {currentTestTwelveExAnswer ? currentTestTwelveExAnswer.join(", ") : ""}</p>
                    </div>
                    </section> : ""}
{/* 13 */}
                {currentTestThirteenEx.length !== 0 ?
                    <section key={currentTestThirteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestThirteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 13</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestThirteenEx.question}</h3>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                    {currentTestThirteenEx.imageFirstRef !== "" ? <p className="flex flex-row items-center">1)<img src={currentTestThirteenEx.imageFirstRef} className="h-auto w-36" /></p> : <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestThirteenEx.answer1)}}></p>}
                    {currentTestThirteenEx.imageSecondRef !== "" ? <p className="flex flex-row items-center">2)<img src={currentTestThirteenEx.imageSecondRef} className="h-auto w-36" /></p> : <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestThirteenEx.answer2)}}></p>}
                    {currentTestThirteenEx.imageThirdRef !== "" ? <p className="flex flex-row items-center">3)<img src={currentTestThirteenEx.imageThirdRef} className="h-auto w-36" /></p> : <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestThirteenEx.answer3)}}></p>}
                    {currentTestThirteenEx.imageFourthRef !== "" ? <p className="flex flex-row items-center">4)<img src={currentTestThirteenEx.imageFourthRef} className="h-auto w-36" /></p> : <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestThirteenEx.answer4)}}></p>}
                    {currentTestThirteenEx.imageFifthRef !== "" ? <p className="flex flex-row items-center">5)<img src={currentTestThirteenEx.imageFifthRef} className="h-auto w-36" /></p> : <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestThirteenEx.answer5)}}></p>}
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestThirteenExAnswer1} onChange={(e) => setCurrentTestThirteenExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                        <input value={currentTestThirteenExAnswer2} onChange={(e) => setCurrentTestThirteenExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                    </div>
                    </section> : ""}
{/* 14 */}
                {currentTestFourteenEx.length !== 0 ?
                    <section key={currentTestFourteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestFourteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 14</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestFourteenEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestFourteenEx.nameOfSubstance}</h3>
                            {currentTestFourteenEx.imageFirstRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">А)<img src={currentTestFourteenEx.imageFirstRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500">А) {currentTestFourteenEx.substance1}</p>}
                            {currentTestFourteenEx.imageSecondRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestFourteenEx.imageSecondRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500">Б) {currentTestFourteenEx.substance2}</p>}
                            {currentTestFourteenEx.imageThirdRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">В)<img src={currentTestFourteenEx.imageThirdRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500">В) {currentTestFourteenEx.substance3}</p>}
                            {currentTestFourteenEx.imageFourthRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Г)<img src={currentTestFourteenEx.imageFourthRefSubstance} className="h-auto w-72" /></p> : <p className=" text-gray-500">Г) {currentTestFourteenEx.substance4}</p>}
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center font-semibold">{currentTestFourteenEx.nameOfReagent}</h3>
                            {currentTestFourteenEx.imageFirstRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestFourteenEx.imageFirstRefReagent} className="h-auto w-72" /></p> : <p>1) {currentTestFourteenEx.reagent1}</p>}
                            {currentTestFourteenEx.imageSecondRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">1)<img src={currentTestFourteenEx.imageSecondRefReagent} className="h-auto w-72" /></p> : <p>2) {currentTestFourteenEx.reagent2}</p>}
                            {currentTestFourteenEx.imageThirdRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">3)<img src={currentTestFourteenEx.imageThirdRefReagent} className="h-auto w-72" /></p> : <p>3) {currentTestFourteenEx.reagent3}</p>}
                            {currentTestFourteenEx.imageFourthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">4)<img src={currentTestFourteenEx.imageFourthRefReagent} className="h-auto w-72" /></p> : <p>4) {currentTestFourteenEx.reagent4}</p>}
                            {currentTestFourteenEx.imageFifthRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">5)<img src={currentTestFourteenEx.imageFifthRefReagent} className="h-auto w-72" /></p> : <p>5) {currentTestFourteenEx.reagent5}</p>}
                            {currentTestFourteenEx.imageSixRefReagent !== "" ? <p className="flex flex-row items-center text-gray-500">6)<img src={currentTestFourteenEx.imageSixRefReagent} className="h-auto w-72" /></p> : <p>6) {currentTestFourteenEx.reagent6}</p>}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestFourteenExAnswer1} onChange={(e) => setCurrentTestFourteenExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFourteenExAnswer2} onChange={(e) => setCurrentTestFourteenExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFourteenExAnswer3} onChange={(e) => setCurrentTestFourteenExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFourteenExAnswer4} onChange={(e) => setCurrentTestFourteenExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 15 */}
                {currentTestFifteenEx.length !== 0 ?
                    <section key={currentTestFifteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestFifteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 15</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestFifteenEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestFifteenEx.nameOfSubstance}</h3>
                            {currentTestFifteenEx.imageFirstRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">А)<img src={currentTestFifteenEx.imageFirstRefSubstance} className="h-auto w-64" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestFifteenEx.substance1)}}></p>}
                            {currentTestFifteenEx.imageSecondRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestFifteenEx.imageSecondRefSubstance} className="h-auto w-64" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestFifteenEx.substance2)}}></p>}
                            {currentTestFifteenEx.imageThirdRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Б)<img src={currentTestFifteenEx.imageThirdRefSubstance} className="h-auto w-64" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestFifteenEx.substance3)}}></p>}
                            {currentTestFifteenEx.imageFourthRefSubstance !== "" ? <p className="flex flex-row items-center text-gray-500">Г)<img src={currentTestFifteenEx.imageFourthRefSubstance} className="h-auto w-64" /></p> : <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestFifteenEx.substance4)}}></p>}
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center font-semibold">{currentTestFifteenEx.nameOfReagent}</h3>
                            {currentTestFifteenEx.imageFirstRefReagent !== "" ? <p className="flex flex-row items-center">1)<img src={currentTestFifteenEx.imageFirstRefReagent} className="h-auto w-64" /></p> : <p>1) {currentTestFifteenEx.reagent1}</p>}
                            {currentTestFifteenEx.imageSecondRefReagent !== "" ? <p className="flex flex-row items-center">2)<img src={currentTestFifteenEx.imageSecondRefReagent} className="h-auto w-64" /></p> : <p>2) {currentTestFifteenEx.reagent2}</p>}
                            {currentTestFifteenEx.imageThirdRefReagent !== "" ? <p className="flex flex-row items-center">3)<img src={currentTestFifteenEx.imageThirdRefReagent} className="h-auto w-64" /></p> : <p>3) {currentTestFifteenEx.reagent3}</p>}
                            {currentTestFifteenEx.imageFourthRefReagent !== "" ? <p className="flex flex-row items-center">4)<img src={currentTestFifteenEx.imageFourthRefReagent} className="h-auto w-64" /></p> : <p>4) {currentTestFifteenEx.reagent4}</p>}
                            {currentTestFifteenEx.imageFifthRefReagent !== "" ? <p className="flex flex-row items-center">5)<img src={currentTestFifteenEx.imageFifthRefReagent} className="h-auto w-64" /></p> : <p>5) {currentTestFifteenEx.reagent5}</p>}
                            {currentTestFifteenEx.imageSixRefReagent == "" && currentTestFifteenEx.reagent6 == "" ? "" : currentTestFifteenEx.imageSixRefReagent !== "" ? <p className="flex flex-row items-center">6)<img src={currentTestFifteenEx.imageSixRefReagent} className="h-auto w-64" /></p> : <p>6) {currentTestFifteenEx.reagent6}</p>}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifteenExAnswer1} onChange={(e) => setCurrentTestFifteenExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifteenExAnswer2} onChange={(e) => setCurrentTestFifteenExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifteenExAnswer3} onChange={(e) => setCurrentTestFifteenExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestFifteenExAnswer4} onChange={(e) => setCurrentTestFifteenExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 16 */}
                {currentTestSixteenEx.length !== 0 ?
                    <section key={currentTestSixteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSixteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 16</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSixteenEx.question}</h3>
                    <div className="flex justify-center">
                        <img src={currentTestSixteenEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSixteenEx.answer1)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSixteenEx.answer2)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSixteenEx.answer3)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSixteenEx.answer4)}}></p>
                        <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSixteenEx.answer5)}}></p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">X</td>
                                <td className="border border-black text-center pb-2">Y</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestSixteenExAnswer1} onChange={(e) => setCurrentTestSixteenExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestSixteenExAnswer2} onChange={(e) => setCurrentTestSixteenExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 17 */}
                {currentTestSeventeenEx.length !== 0 ?
                    <section key={currentTestSeventeenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestSeventeenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 17</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestSeventeenEx.question}</h3>
                    <div className="flex justify-center">
                        <img src={currentTestSeventeenEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSeventeenEx.answer1)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSeventeenEx.answer2)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSeventeenEx.answer3)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSeventeenEx.answer4)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSeventeenEx.answer5)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer17} className="w-4 h-4 mr-2" id="6answercheckbox" name="checkbox17" />
                            <p dangerouslySetInnerHTML={{__html: "6) "+containsNumber(currentTestSeventeenEx.answer6)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ: {currentTestSeventeenExAnswer ? currentTestSeventeenExAnswer.join(", ") : ""}</p>
                    </div>
                    </section> : ""}
{/* 18 */}
                {currentTestEighteenEx.length !== 0 ?
                    <section key={currentTestEighteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestEighteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 18</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestEighteenEx.question}</h3>
                    <div className="flex justify-center">
                        <img src={currentTestEighteenEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer18} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox18" />
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestEighteenEx.answer1)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer18} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox18" />
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestEighteenEx.answer2)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer18} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox18" />
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestEighteenEx.answer3)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer18} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox18" />
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestEighteenEx.answer4)}}></p>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer18} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox18" />
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestEighteenEx.answer5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ: {currentTestEighteenExAnswer ? currentTestEighteenExAnswer.join(", ") : ""}</p>
                    </div>
                    </section> : ""}
{/* 19 */}
                {currentTestNineteenEx.length !== 0 ?
                    <section key={currentTestNineteenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestNineteenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 19</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestNineteenEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestNineteenEx.nameOfSubstance}</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestNineteenEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestNineteenEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestNineteenEx.substance3)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">{currentTestNineteenEx.nameOfReagent}</h3>
                            <p>1) {currentTestNineteenEx.reagent1}</p>
                            <p>2) {currentTestNineteenEx.reagent2}</p>
                            <p>3) {currentTestNineteenEx.reagent3}</p>
                            <p>4) {currentTestNineteenEx.reagent4}</p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestNineteenExAnswer1} onChange={(e) => setCurrentTestNineteenExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestNineteenExAnswer2} onChange={(e) => setCurrentTestNineteenExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestNineteenExAnswer3} onChange={(e) => setCurrentTestNineteenExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 20 */}
                {currentTestTwentyEx.length !== 0 ?
                    <section key={currentTestTwentyEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 20</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwentyEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestTwentyEx.nameOfSubstance}</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyEx.substance3)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">{currentTestTwentyEx.nameOfReagent}</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyEx.reagent3)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTwentyEx.reagent4)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTwentyEx.reagent5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyExAnswer1} onChange={(e) => setCurrentTestTwentyExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyExAnswer2} onChange={(e) => setCurrentTestTwentyExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyExAnswer3} onChange={(e) => setCurrentTestTwentyExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 21 */}
                {currentTestTwentyOneEx.length !== 0 ?
                    <section key={currentTestTwentyOneEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyOneEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 21</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwentyOneEx.question}</h3>
                    <div className="flex justify-center sm:justify-start md:justify-start lg:justify-start">
                        <img src={currentTestTwentyOneEx.imageRef} className="rounded-lg h-auto max-w-full sm:max-w-2xl md:max-w-2xl lg:max-w-2xl" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestTwentyOneEx.answer1}</p>
                        <p>2) {currentTestTwentyOneEx.answer2}</p>
                        <p>3) {currentTestTwentyOneEx.answer3}</p>
                        <p>4) {currentTestTwentyOneEx.answer4}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyOneExAnswer1} onChange={(e) => setCurrentTestTwentyOneExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                        <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl">→</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyOneExAnswer2} onChange={(e) => setCurrentTestTwentyOneExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                        <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl">→</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyOneExAnswer3} onChange={(e) => setCurrentTestTwentyOneExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                        <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl">→</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyOneExAnswer4} onChange={(e) => setCurrentTestTwentyOneExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 22 */}
                {currentTestTwentyTwoEx.length !== 0 ?
                    <section key={currentTestTwentyTwoEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyTwoEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 22</h5>
                    <h3 className="mb-0 font-semibold text-black" dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwentyTwoEx.question)}}></h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestTwentyTwoEx.nameOfSubstance}</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyTwoEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyTwoEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyTwoEx.substance3)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestTwentyTwoEx.substance4)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">{currentTestTwentyTwoEx.nameOfReagent}</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyTwoEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyTwoEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyTwoEx.reagent3)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyTwoExAnswer1} onChange={(e) => setCurrentTestTwentyTwoExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyTwoExAnswer2} onChange={(e) => setCurrentTestTwentyTwoExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyTwoExAnswer3} onChange={(e) => setCurrentTestTwentyTwoExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyTwoExAnswer4} onChange={(e) => setCurrentTestTwentyTwoExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 23 */}
                {currentTestTwentyThreeEx.length !== 0 ?
                    <section key={currentTestTwentyThreeEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyThreeEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 23</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwentyThreeEx.question}</h3>
                    <div className="flex justify-center">
                        <img src={currentTestTwentyThreeEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <p>1) {currentTestTwentyThreeEx.answer1}</p>
                        <p>2) {currentTestTwentyThreeEx.answer2}</p>
                        <p>3) {currentTestTwentyThreeEx.answer3}</p>
                        <p>4) {currentTestTwentyThreeEx.answer4}</p>
                        <p>5) {currentTestTwentyThreeEx.answer5}</p>
                        <p>6) {currentTestTwentyThreeEx.answer6}</p>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">X</td>
                                <td className="border border-black text-center pb-2">Y</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyThreeExAnswer1} onChange={(e) => setCurrentTestTwentyThreeExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyThreeExAnswer2} onChange={(e) => setCurrentTestTwentyThreeExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 24 */}
                {currentTestTwentyFourEx.length !== 0 ?
                    <section key={currentTestTwentyFourEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyFourEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 24</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwentyFourEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestTwentyFourEx.nameOfSubstance}</h3>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyFourEx.substance1)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyFourEx.substance2)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyFourEx.substance3)}}></p>
                            <p className=" text-gray-500" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestTwentyFourEx.substance4)}}></p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">{currentTestTwentyFourEx.nameOfReagent}</h3>
                            <p dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyFourEx.reagent1)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyFourEx.reagent2)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyFourEx.reagent3)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTwentyFourEx.reagent4)}}></p>
                            <p dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTwentyFourEx.reagent5)}}></p>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                                <td className="border border-black text-center pb-2">Г</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFourExAnswer1} onChange={(e) => setCurrentTestTwentyFourExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFourExAnswer2} onChange={(e) => setCurrentTestTwentyFourExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFourExAnswer3} onChange={(e) => setCurrentTestTwentyFourExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFourExAnswer4} onChange={(e) => setCurrentTestTwentyFourExAnswer4(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}
{/* 25 */}
                {currentTestTwentyFiveEx.length !== 0 ?
                    <section key={currentTestTwentyFiveEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyFiveEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 25</h5>
                    <h3 className="mb-0 font-semibold text-black">{currentTestTwentyFiveEx.question}</h3>
                    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-start space-x-0 sm:space-x-20 md:space-x-20 lg:space-x-20 items-start">
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                            <h3 className="text-center text-gray-500 font-semibold">{currentTestTwentyFiveEx.nameOfSubstance}</h3>
                            <p className=" text-gray-500">А) {currentTestTwentyFiveEx.substance1}</p>
                            <p className=" text-gray-500">Б) {currentTestTwentyFiveEx.substance2}</p>
                            <p className=" text-gray-500">В) {currentTestTwentyFiveEx.substance3}</p>
                        </div>
                        <div className="flex flex-col space-y-2 justify-center font-bold mt-5 mb-5 text-xl">
                        <h3 className="text-center font-semibold">{currentTestTwentyFiveEx.nameOfReagent}</h3>
                            <p>1) {currentTestTwentyFiveEx.reagent1}</p>
                            <p>2) {currentTestTwentyFiveEx.reagent2}</p>
                            <p>3) {currentTestTwentyFiveEx.reagent3}</p>
                            {currentTestTwentyFiveEx.reagent4 ? <p>4) {currentTestTwentyFiveEx.reagent4}</p> : ""}
                            {currentTestTwentyFiveEx.reagent5 ? <p>4) {currentTestTwentyFiveEx.reagent5}</p> : ""}
                        </div>
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <table className="border-collapse border border-black text-center">
                            <tr>
                                <td className="border border-black text-center pb-2">А</td>
                                <td className="border border-black text-center pb-2">Б</td>
                                <td className="border border-black text-center pb-2">В</td>
                            </tr>
                            <tr>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFiveExAnswer1} onChange={(e) => setCurrentTestTwentyFiveExAnswer1(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFiveExAnswer2} onChange={(e) => setCurrentTestTwentyFiveExAnswer2(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                                <td className="border border-black text-center">
                                    <input value={currentTestTwentyFiveExAnswer3} onChange={(e) => setCurrentTestTwentyFiveExAnswer3(e.target.value)} className="text-center appearance-none w-12 block bg-white text-gray-700 border border-gray-100 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </section> : ""}

                {currentTestTwentySixEx.length !== 0 ?
                    <div className="flex justify-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/chemistry-c3a1e.appspot.com/o/imageBase%2Fimage_3b3e8f7d-c6e9-4be0-a1eb-4412148662c4?alt=media&token=badda8c7-cd81-4705-a3ba-be619d27a1a6" className="rounded-lg h-auto max-w-full sm:max-w-xl md:max-w-xl lg:max-w-xl" />
                    </div> : ""}
{/* 26 */}
                {currentTestTwentySixEx.length !== 0 ?
                    <section key={currentTestTwentySixEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentySixEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 26</h5>
                    <h3 className="mb-0 font-semibold text-black mb-2">{currentTestTwentySixEx.question}</h3>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestTwentySixExAnswer} onChange={(e) => setCurrentTestTwentySixExAnswer(e.target.value)} className="text-center appearance-none w-24 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>&nbsp;{currentTestTwentySixEx.measuring}
                    </div>
                    </section> : ""}
{/* 27 */}
                {currentTestTwentySevenEx.length !== 0 ?
                    <section key={currentTestTwentySevenEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentySevenEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 27</h5>
                    <h3 className="mb-0 font-semibold text-black mb-2" dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwentySevenEx.question)}}></h3>
                    <div className="flex justify-center">
                        <img src={currentTestTwentySevenEx.imageRefTask} className="rounded-lg h-auto max-w-sm" />
                    </div>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestTwentySevenExAnswer} onChange={(e) => setCurrentTestTwentySevenExAnswer(e.target.value)} className="text-center appearance-none w-24 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>&nbsp;{currentTestTwentySevenEx.measuring}
                    </div>
                    </section> : ""}
{/* 28 */}
                {currentTestTwentyEightEx.length !== 0 ?
                    <section key={currentTestTwentyEightEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4" id={currentTestTwentyEightEx.id}>
                    <h5 className='mb-0 text-sm text-black text-left'>Задание 28</h5>
                    <h3 className="mb-0 font-semibold text-black mb-2">{currentTestTwentyEightEx.question}</h3>
                    <div className="flex flex-row space-x-1 items-center mb-2">
                        <p>Ответ:</p>
                        <input value={currentTestTwentyEightExAnswer} onChange={(e) => setCurrentTestTwentyEightExAnswer(e.target.value)} className="text-center appearance-none w-24 block bg-white text-gray-700 border border-gray-500 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>&nbsp;{currentTestTwentyEightEx.measuring}
                    </div>
                    </section> : ""}

                    {/* <button onClick={checkAllAnswers} className="w-64 bg-orange-300 hover:bg-orange-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Проверить ответы</button> */}

{/* 29 */}
                    {currentTestTwentyNineEx.length !== 0 ?
                        <section key={currentTestTwentyNineEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 29</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestTwentyNineEx.question}</h3>
                        <div className="flex justify-center">
                            <img src={currentTestTwentyNineEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                        </div>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload29(event.target.files[0])}} />
                            <button onClick={uploadImage29} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload29}</button>
                        </div>
                        </section> : ""}
{/* 30 */}
                    {currentTestThirtyEx.length !== 0 ?
                        <section key={currentTestThirtyEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 30</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestThirtyEx.question}</h3>
                        <div className="flex justify-center">
                            <img src={currentTestThirtyEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                        </div>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload30(event.target.files[0])}} />
                            <button onClick={uploadImage30} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload30}</button>
                        </div>
                        </section> : ""}
{/* 31 */}
                    {currentTestThirtyOneEx.length !== 0 ?
                        <section key={currentTestThirtyOneEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 31</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestThirtyOneEx.question}</h3>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload31(event.target.files[0])}} />
                            <button onClick={uploadImage31} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload31}</button>
                        </div>
                        </section> : ""}
{/* 32 */}
                    {currentTestThirtyTwoEx.length !== 0 ?
                        <section key={currentTestThirtyTwoEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 32</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestThirtyTwoEx.question}</h3>
                        <div className="flex justify-center">
                            <img src={currentTestThirtyTwoEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        </div>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload32(event.target.files[0])}} />
                            <button onClick={uploadImage32} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload32}</button>
                        </div>
                        </section> : ""}
{/* 33 */}
                    {currentTestThirtyThreeEx.length !== 0 ?
                        <section key={currentTestThirtyThreeEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 33</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestThirtyThreeEx.question}</h3>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload33(event.target.files[0])}} />
                            <button onClick={uploadImage33} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload33}</button>
                        </div>
                        </section> : ""}
{/* 34 */}
                    {currentTestThirtyFourEx.length !== 0 ?
                        <section key={currentTestThirtyFourEx.id} className="ring-offset-0 ring bg-white py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-left px-4">
                        <h5 className='mb-0 text-sm text-black text-left'>Задание 34</h5>
                        <h3 className="mb-0 font-semibold text-black mb-2">{currentTestThirtyFourEx.question}</h3>
                        <div className="flex flex-row space-x-1 items-center mb-2">
                            <p className="text-right mr-2">Прикрепить ответ:</p>
                            <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload34(event.target.files[0])}} />
                            <button onClick={uploadImage34} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload34}</button>
                        </div>
                        </section> : ""}
{/* Кнопки */}
                        {currentTestFirstEx.length !== 0 ?
                        <button onClick={hadleCompleteExerciseFirstPart} className="w-64 bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        {isLoading ? "Ждите..." : "Завершить часть 1"}
                        </button> : ""}

                        {currentTestTwentyNineEx.length !== 0 ?
                        <button onClick={hadleCompleteExerciseSecondPart} className="w-72 bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        {isLoadingSecond ? "Ждите..." : "Завершить часть 2"}
                    </button> : ""}
                {/* </div> */}
            </section>
        </div>
    )
}

function PassedTests({ currentUserId }) {
    const [currentStudentResult, setCurrentStudentResult] = useState();

    const chooseStudenthandle = async () => {
        setCurrentStudentResult();
        const userAnswersRef = collection(database, "userAnswers");
        const q = query(userAnswersRef, where("userId", "==", currentUserId));
        const querySnapshot = await getDocs(q);
        let tempArray = [];
        querySnapshot.forEach((doc) => {
        tempArray.push(doc.data());
        });
        setCurrentStudentResult(tempArray);
    };

    useEffect(()=>{
        chooseStudenthandle();
    },[])

    return(
        <div className="flex flex-col justify-center px-3 py-2 bg-gray-300">
            {currentStudentResult ? 
                currentStudentResult.map(item => {
                    return <section key={item.date} className="flex flex-col justify-center px-3 py-2 space-y-4 bg-white rounded-lg mb-1 mt-2">
                        <div>Тест №{item.testNumber.toString().slice(0, -1)}</div>
                        <div>Дата прохождения: {item.date.toDate().getDate()+"."+(item.date.toDate().getMonth()+1)+"."+item.date.toDate().getFullYear()} в {item.date.toDate().toLocaleTimeString('ru-RU')}</div>
                        <h3>Баллов за 1 часть: <span style={{fontSize: 24, fontWeight: 'bold'}}>{item.score} / 36</span></h3>
                        <h3 style={{fontWeight: 'bold'}}>Мои ответы на 1 часть:</h3>
                        <div className="space-y-2 sm:space-y-4 md:space-y-4 lg:space-y-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:md:grid-cols-4 content-end">
                        <div className="content-end" style={{marginLeft: 15}}>1) <span style={{backgroundColor: item.isRight1 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question1.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>2) <span style={{backgroundColor: item.isRight2 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question2.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>3) <span style={{backgroundColor: item.isRight3 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question3.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>4) <span style={{backgroundColor: item.isRight4 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question4.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>5) <span style={{backgroundColor: item.isRight5 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question5.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>6) <span style={{backgroundColor: item.isRight6 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question6.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>7) <span style={{backgroundColor: item.isRight7 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question7.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>8) <span style={{backgroundColor: item.isRight8 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question8.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>9) <span style={{backgroundColor: item.isRight9 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question9.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>10) <span style={{backgroundColor: item.isRight10 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question10.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>11) <span style={{backgroundColor: item.isRight11 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question11.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>12) <span style={{backgroundColor: item.isRight12 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question12.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>13) <span style={{backgroundColor: item.isRight13 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question13.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>14) <span style={{backgroundColor: item.isRight14 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question14.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>15) <span style={{backgroundColor: item.isRight15 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question15.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>16) <span style={{backgroundColor: item.isRight16 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question16.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>17) <span style={{backgroundColor: item.isRight17 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question17.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>18) <span style={{backgroundColor: item.isRight18 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question18.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>19) <span style={{backgroundColor: item.isRight19 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question19.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>20) <span style={{backgroundColor: item.isRight20 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question20.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>21) <span style={{backgroundColor: item.isRight21 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question21.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>22) <span style={{backgroundColor: item.isRight22 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question22.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>23) <span style={{backgroundColor: item.isRight23 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question23.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>24) <span style={{backgroundColor: item.isRight24 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question24.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>25) <span style={{backgroundColor: item.isRight25 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question25.join(", ")}</span></div>
                        <div style={{marginLeft: 15}}>26) <span style={{backgroundColor: item.isRight26 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question26}</span></div>
                        <div style={{marginLeft: 15}}>27) <span style={{backgroundColor: item.isRight27 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question27}</span></div>
                        <div style={{marginLeft: 15}}>28) <span style={{backgroundColor: item.isRight28 ? '#4ade80' : '#fda4af', padding: 4, borderRadius: 5}}>{item.question28}</span></div>
                        </div>
                        <h3 style={{fontWeight: 'bold'}}>Мои ответы на 2 часть:</h3>
                        <div style={{marginLeft: 15}}>29 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question29}>Посмотреть ответ</a></div>
                        <div style={{marginLeft: 15}}>30 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question30}>Посмотреть ответ</a></div>
                        <div style={{marginLeft: 15}}>31 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question31}>Посмотреть ответ</a></div>
                        <div style={{marginLeft: 15}}>32 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question32}>Посмотреть ответ</a></div>
                        <div style={{marginLeft: 15}}>33 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question33}>Посмотреть ответ</a></div>
                        <div style={{marginLeft: 15}}>34 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question34}>Посмотреть ответ</a></div>
                    </section> 
                })
                : ""}
        </div>
    )
}